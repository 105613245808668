import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Carousel from "react-bootstrap/Carousel"
import Layout from "../components/layout"
import Img from "gatsby-image" // to take image data and render it
import { graphql } from "gatsby"
import usePositionFooter from "../util/position-footer"

export default function Home({ data, location }) {
  const [copyrightFooterRef, runAfterImagesLoaded] = usePositionFooter(location)

  return (
    <Layout location={location} ref={copyrightFooterRef}>
      <Row>
        <Col>
          <Carousel pause={false} interval={2500}>
            {data.allFile.edges.map((edge, i) => (
              <Carousel.Item key={i}>
                <Img
                  onLoad={runAfterImagesLoaded}
                  className="d-block w-100"
                  fixed={edge.node.childImageSharp.fixed}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  {
    allFile(filter: { sourceInstanceName: { eq: "goo" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            fixed(height: 400, fit: OUTSIDE, cropFocus: ATTENTION) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
